"use strict";

angular.module("informaApp")
    .service("CursorAnimation", function () {
        return {
            wait: {
                enable: function () {
                    $("html").css("cursor", "wait");
                },
                disable: function () {
                    $("html").css("cursor", "");
                }
            }
        }
    });